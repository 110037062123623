import equipment from "../assets/compressed/Equipment rental-min.png";
import audiovisual from "../assets/compressed/livestreaming.jpg";
import logisticsAdvisory from "../assets/compressed/EF-exhibition-min.jpg";
import interpretation from "../assets/compressed/simulitaneousInterpretation-min.jpg";
import accreditation from "../assets/compressed/EF Reg 7-min.jpg";

export default {
  noheader: true,
  firstSection: {
    classname: "commonpage-eventsmanagement",
    heading: "Equipment rental",
    text1: `We have an elaborate list of rental items that cover secretariat equipment, sound and lighting systems, exhibition furniture and display cases.`,
    text2: ``,
    ifoto: "",
    alt: "equipment rental",
    link: "/contact",
  },
  secondSection: {
    classname: "speaker__second-section",
    text: ``,
    heading: "Furnitures",
    ifoto: equipment,
    ulList: [
      {
        id: 1,
        text: `Chairs (Sofas, Bar stools, Foldable chairs, Office chairs, etc)`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: ` Tables (Cocktail tables, Speaker tables, Round tables, etc)`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: `Counters (Exhibition counters, Accreditation counters, etc)`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: `Lacterns`,
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },

  thirdSection: {
    heading: "Audio Visuals",
    alt: "speaker",
    ifoto: audiovisual,
    ulList: [
      {
        id: 1,
        text: "Sound System  (PA system, speakers, microphone)",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "Lighting  (Stage Lighting, Decorative lighting)",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "TV Screens  (LED screens, different sizes)",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "Projectors",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 5,
        text: "Microphones  (wireless, push-to-talk microphones)",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
  fourthSection: {
    heading: "Exhibition",
    alt: "speaker",
    ifoto: logisticsAdvisory,
    ulList: [
      {
        id: 1,
        text: " Booths  (standard shell scheme)",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "Display TV’s  ",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "Showcase cabinets",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "Carpets",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 5,
        text: "Storage",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 6,
        text: "Brochure stands",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 7,
        text: "Exhibition carpets",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
  fifthSection: {
    heading: "Interpretation Equipment",
    alt: "speaker",
    ifoto: interpretation,
    ulList: [
      {
        id: 1,
        text: " Microphone (Goosnecks, Table mics, Handheld, Lavalier, etc)",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "interpretation booths",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "Receivers",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "Receiver Headsets and earphones",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 5,
        text: "Transmitters ",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 6,
        text: "Infrared reception system",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
  sixthSection: {
    heading: "Accreditation",
    alt: "speaker",
    ifoto: accreditation,
    ulList: [
      {
        id: 1,
        text: " Printers",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "Laptops",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "copiers",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "shredders",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 5,
        text: "Lanyards & Pouches",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
};
