import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainButton from "./MainButton";
import SocialIcons from "./SocialIcons";
import SubMenuUls from "./SubMenuUls";
import imigongo from "../assets/images/EFwebimigongo patterns-03-min.png";

function MenuLi({ mainlink, submenuColumns, more }) {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <li className="li-main" onClick={() => setShowSubmenu(!showSubmenu)}>
      <Link
        className="link a-main"
        style={{
          display: "flex",
        }}
        href=""
      >
        {mainlink}
        {more ? (
          <div style={{ textAlign: "center", marginLeft: "5px" }}>
            <i className="fas fa-angle-down"></i>
          </div>
        ) : (
          ""
        )}
      </Link>
      <div
        className="menu-sub"
        style={{ display: `${showSubmenu ? "block" : "none"}` }}
        onMouseLeave={() => setShowSubmenu(false)}
      >
        <SubMenuUls submenuColumns={submenuColumns} />
        <div className="button-social">
          <MainButton
            text="Book now"
            url="https://smartbookings.rw/Home/"
            iconName="arrow-right"
          />
          <div className="menu-footer--socialIcons">
            <SocialIcons />
          </div>
        </div>
        <div className="menu-footer">
          <img className="imigongo" src={imigongo} alt="imigongo" />
        </div>
      </div>
    </li>
  );
}

export default MenuLi;
