import React from "react";
import Layout from "../../layouts/Layout";
import delegateInfo from "../../dummyData/equipments";
import CommonPage from "../../components/common/CommonPage";
import CircleImage from "../../components/common/CircleImage";
import ContactBox from "../../components/common/ContactBox";
import FirstSmallSection from "../../components/FirstSmallSection";
import ceo from "../../assets/team/makuza.jpg";


const contacts = [
  {
    id: 1,
    name: "Bruno Mureke",
    position: ` Digital Solutions & Reservations`,
    profile: ceo,
    info: [
      {
        id: 2,
        name: "+250 789 023 900",
        icon: "fa-phone-alt",
      },
      {
        id: 3,
        name: "habinezadalvan@gmail.com",
        icon: "far fa-envelope",
      },
    ],
  },
  {
    id: 2,
    name: "Younnouss Uwase",
    position: `Head Of Operations & Logistics`,
    profile: `https://content.fortune.com/wp-content/uploads/2015/04/purdie-vaughns-photo.jpeg`,
    info: [
      {
        id: 2,
        name: "+250 789 023 900",
        icon: "fa-phone-alt",
      },
      {
        id: 3,
        name: "rukundoemmanuel@gmail.com",
        icon: "far fa-envelope",
      },
    ],
  },
  {
    id: 3,
    name: "Aime Malaika",
    position: `Senior Web Developer`,
    profile: `https://content.fortune.com/wp-content/uploads/2015/04/purdie-vaughns-photo.jpeg`,
    info: [
      {
        id: 2,
        name: "+250 789 023 900",
        icon: "fa-phone-alt",
      },
      {
        id: 3,
        name: "rukundoemmanuel@gmail.com",
        icon: "far fa-envelope",
      },
    ],
  },
  {
    id: 4,
    name: "Ritah Rwangyezi",
    position: `Branding & Communications`,
    profile: `https://content.fortune.com/wp-content/uploads/2015/04/purdie-vaughns-photo.jpeg`,
    info: [
      {
        id: 2,
        name: "+250 789 023 900",
        icon: "fa-phone-alt",
      },
      {
        id: 3,
        name: "rukundoemmanuel@gmail.com",
        icon: "far fa-envelope",
      },
    ],
  },
];

const data = {
  id: 1,
  heading: "Contact us",
  text: ` If you have any enquiries about our services or are planning an
              event, we are here to help!`,
};

function ContactUs() {
  return (
    <Layout>
      <div className="contact">
        <FirstSmallSection data={data} />
        {/* <div className="first-section">
          <div className="inner">
            <h1>Contact us</h1>
            <p>
              If you have any enquiries about our services or are planning an
              event, we are here to help!
            </p>
          </div>
        </div> */}
        <div className="second-section">
          <div className="second-section-left">
            <div className="inner">
              <div>
                {/* <h1>Company's info</h1> */}
                <div className="contact-info">
                  <div className="icon-container">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <ul>
                    <li>KG 33 Ave, Kigali</li>
                    <li>P.O. Box 3397 Kigali, Rwanda</li>
                  </ul>
                </div>

                <div className="contact-info">
                  <div className="icon-container">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  <p>+250 (0) 788 322 335</p>
                </div>
                <div className="contact-info">
                  <div className="icon-container">
                    <i class="far fa-envelope"></i>
                  </div>
                  <p>info@eventsfactory.rw</p>
                </div>
              </div>
            </div>
          </div>
          <div className="second-section-right">
            <h1>Leave a message</h1>
            <form>
              <input type="text" placeholder="Name" required />
              <input type="text" placeholder="Email" required />
              <input type="text" placeholder="Subject" required />
              <textarea placeholder="Leave a message..." required />
              <button className="main-btn" type="submit">
                Send message
              </button>
            </form>
          </div>
        </div>
        <p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.5823592160164!2d30.069922314837036!3d-1.9182865371833313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca1512d3d59e3%3A0x46adbb7332653a48!2sEvents%20Factory!5e0!3m2!1sen!2srw!4v1610439311081!5m2!1sen!2srw"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: "0" }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </p>
      </div>
    </Layout>
  );
}

export default ContactUs;
