import React from "react";
import imigongo from "../../assets/images/EFwebimigongo patterns-03-min.png";

function PlanBox({ data }) {
  const displayList = () =>
    data.list.map((l) => (
      <li key={l.id}>
        <i class="fas fa-chevron-circle-right"></i>
        <p>{l.text}</p>
      </li>
    ));
  return (
    <div className="plan-box">
      <div className="plan-title">
        <h1>{data.title}</h1>
        <div className="plan-title-bg">
          <img src={imigongo} alt="imigongo" />
        </div>
      </div>
      <ul>{displayList()}</ul>
      <div className="plan-box-footer">
        <h1>{data.cost}</h1>
      </div>
    </div>
  );
}

export default PlanBox;
