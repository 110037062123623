import React from "react";
import HeadingWrapper from "./HeadingWrapper";

function CommonListSection({ data }) {
  const displayList = () =>
    data.ulList.map((list) => (
      <li key={list.id}>
        <i class={list.icon}></i>
        <p>{list.text}</p>
      </li>
    ));
  return (
    <div className="common-list-section">
      <div className="left">
        <img src={data.ifoto} alt={data.alt} />
      </div>
      <div className="right">
        <div>
          <HeadingWrapper className="uncenteredHeading" text={data.heading} />
          <p>{data.text}</p>
          <ul>{displayList()}</ul>
        </div>
      </div>
    </div>
  );
}

export default CommonListSection;
