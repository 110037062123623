import React from "react";
import HeadingWrapper from "../../components/common/HeadingWrapper";

function ThirdSectionRegistration({title, text}) {
  return (
    <div className="registration__third-section">
      <HeadingWrapper text={title} />
      <p>
        {text}
      </p>
    </div>
  );
}

export default ThirdSectionRegistration;
