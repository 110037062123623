import React from "react";
import EventManagementFirstSection from "../../pages/EventManagement/EventManagementFirstSection";
import FirstSectionRegistration from "../../pages/Registration/FirstSectionRegistration";
import FirstSmallSection from "../FirstSmallSection";
import CommonListSection from "./CommonListSection";

const data = {
  id: 1,
  heading: "Equipment rental",
  text: ` We have an elaborate list of rental items that cover secretariat equipment, sound and lighting systems, exhibition furniture and display cases.`,
  button: true,
};

function CommonPage({ info }) {
  return (
    <div className="speaker">
      <FirstSmallSection data={data} />

      {info.noheader ? (
        <div> </div>
      ) : (
        <FirstSectionRegistration data={info.firstSection} />
      )}
      <CommonListSection data={info.secondSection} />
      <CommonListSection data={info.thirdSection} />
      {/* <FourthSectionRegistration data={info.thirdSection} /> */}
      <CommonListSection data={info.fourthSection} />
      <CommonListSection data={info.fifthSection} />
      <CommonListSection data={info.sixthSection} />
      {/* <FourthSectionRegistration data={info.fifthSection} />
        <CommonListSection data={info.sixthSection} /> */}
    </div>
  );
}

export default CommonPage;
