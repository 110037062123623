import React from "react";
import MainButton from "./MainButton";

function FirstSmallSection({ data }) {
  return (
    <div className="first-section">
      <div className="inner">
        <h1>{data.heading}</h1>
        <p>{data.text}</p>
      </div>
      {data.button ? (
        <div className="button-div">
          <MainButton
            text="Get in touch"
            url="/contact"
            iconName="arrow-right"
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default FirstSmallSection;
