import React from "react";
import CommnPageTwo from "../../components/common/CommnPageTwo";
import Layout from "../../layouts/Layout";
import data from "../../dummyData/exhibition";
import ababyinnye from "../../assets/compressed/EF-Exbn-8-min.jpg";

function Exhibition() {
  return (
    <Layout>
      <CommnPageTwo data={data} bgimage={ababyinnye} />
    </Layout>
  );
}

export default Exhibition;
