import React, { useState } from "react";
import Popup from "../../components/common/Popup";

const exhibitions = [
  {
    id: 1,
    item: "84th INTERPOL AGA Exhibition",
  },
  {
    id: 2,
    item: "15th Juakali / Nguvu Kazi Regional Exhibition (2014)",
  },
  {
    id: 3,
    item: "Inaugural Cup of Excellence Competition & Expo (2008)",
  },
  {
    id: 4,
    item: "The Second Africa Tea Exhibition",
  },
  {
    id: 5,
    item: "Rwanda International Trade Fairs 2007",
  },
];

function PortifolioCard({ data, setOpenPopup }) {

  return (
    <div
      className="card"
      onClick={
        data.clickable ? () => setOpenPopup(true) : () => setOpenPopup(false)
      }
      style={{ cursor: `${data.clickable ? "pointer" : "auto"}` }}
    >
      <div className="icon-section">
        <img src={data.icon} alt="icon" />
      </div>
      <div className="numbers">
        <p>{data.text}</p>
        <h1>{data.numbers}</h1>
      </div>
      <div></div>
    </div>
  );
}

export default PortifolioCard;
