import React from "react";
import CircleImage from "../../components/common/CircleImage";
import MemberTitleContainer from "../../components/MemberTitleContainer";
import bruno from "../../assets/team/bruno.jpg";
import aime from "../../assets/team/aime.jpg";
import younous from "../../assets/team/younous.jpg";
import ritah from "../../assets/team/Ritah Photo.png";
import augustin from "../../assets/team/auguste-min.jpeg";
import ceo from "../../assets/team/makuza.jpg";

const data = [
  {
    id: 1,
    name: "Celestin Makuza",
    position: `Founder & CEO`,
    profile: ceo,
  },
  {
    id: 4,
    name: "Younnouss Uwase",
    position: `Operations & Logistics`,
    profile: younous,
  },
  {
    id: 3,
    name: "Bruno Mureke",
    position: ` Digital Solutions & Reservations`,
    profile: bruno,
  },
  {
    id: 6,
    name: "Ritah Rwangyezi",
    position: `Branding & Communications`,
    profile: ritah,
  },
  {
    id: 2,
    name: "Augustin Mugiraneza",
    position: `Finance & Administration`,

    profile: augustin,
  },

  {
    id: 5,
    name: "Aime Malaika",
    position: `Senior Software Engineer`,
    profile: aime,
  },
];
function AboutThirdSection() {
  const displayTeam = () =>
    data.map((member) => (
      <div className="member" key={member.id}>
        <CircleImage profile={member.profile} />
        <MemberTitleContainer name={member.name} title={member.position} />
      </div>
    ));
  return (
    <div className="aboutpage__third-section">
      <h1 className="custom-heading">Meet our team</h1>
      <div className="team-container">
        <div className="team-container__left">
          <div className="boxes">
            <div className="box">
              <h1>Vision</h1>
              <p>
                To become the provider of choice for innovative solutions and
                services in Rwanda
              </p>
            </div>

            <div className="box">
              <h1>Mission</h1>
              <p>
                To provide the best customer-centric solutions for conferences
                and events by hiring industrious employees, utilizing innovative
                systems and building strong relationships with our partners.
              </p>
            </div>
          </div>
        </div>
        <div className="team-container__right">
          <div className="team-members">{displayTeam()}</div>
        </div>
      </div>
    </div>
  );
}

export default AboutThirdSection;
