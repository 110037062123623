import React from "react";
import Layout from "../../layouts/Layout";
import delegateInfo from "../../dummyData/equipments";
import CommonPage from "../../components/common/CommonPage";

function Equipment() {
  return (
    <Layout>
      <CommonPage info={delegateInfo} />
    </Layout>
  );
}

export default Equipment;
