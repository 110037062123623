import dataImage from "../assets/svgs/artmuseum.svg";
import stateequipment from "../assets/compressed/Simultaneous-Interpretation-min.jpeg";
import multiplelanguages from "../assets/compressed/EF INT 10-min.jpeg";

export default {
  page: "interpretation",
  firstSection: {
    classname: "commonpage-eventsmanagement",
    heading: "Simultaneous interpretation systems",
    text1: `It takes professional equipment, seasoned technicians and professional interpreters to do this job well and we have the best match of the three.`,
    text2: "",
    ifoto: dataImage,
    alt: "Simulitaneous interpretation",
  },
  secondSection: [
    {
      id: 1,
      ifoto: stateequipment,
      alt: "tools",
      message: `Our top-of-the-line equipment is used in most European Union Parliaments and ensures no radio interferences during sessions.`,
      title: "State of art equipment",
      icon: "fas fa-truck-loading",
      link: "#",
      more: false,
      languages: [],
    },
    {
      id: 2,
      ifoto: multiplelanguages,
      alt: "interpretation",
      message: `In our array of in-house services, simultaneous interpretation services features prominent.
Our interpreters are some of the finest in the region.
With the use of our sophisticated equipment, we interprete up to 7 languages per event.
`,
      title: "Multiple languages",
      icon: "fas fa-headset",
      link: "#",
      more: false,
      languageCard: true,
      to: `to= '/'`,
      languages: [
        // {
        //   id: 1,
        //   language: "English",
        // },
        // {
        //   id: 2,
        //   language: "French",
        // },
        // {
        //   id: 3,
        //   language: "Kinyarwanda",
        // },
        // {
        //   id: 4,
        //   language: "Spanish",
        // },
      ],
    },
    // {
    //   id: 3,
    //   ifoto:
    //     "https://d3hjf51r9j54j7.cloudfront.net/wp-content/uploads/sites/9/2019/01/Bringing-cinematic-filming-technique-and-live-streaming-together.jpg",
    //   alt: "booth type",
    //   message: `Even "Super You" needs help and support. There is no shame in
    //             asking for assistance. Push aside the pride and show respect for
    //             the talent others can bring to the table. And, remember that
    //             there is no such thing as a single-handed success: when you
    //             include and acknowledge all those in your corner, you propel
    //             yourself, your teammates and your supporters to greater heights.`,
    //   title: "Live streaming",
    //   icon: "fas fa-video",
    //   link: "/",
    //   more: false,
    //   languages: [],
    // },
  ],
};
