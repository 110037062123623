import React from "react";
import HeadingWrapper from "../../components/common/HeadingWrapper";


function SecondSectionRegistration({text, heading, ulList, classname}) {
  const displayList = () =>
    ulList.map((list) => (
      <li key={list.id}>
        <i class="fas fa-chevron-circle-right"></i>
        <p>{list.text}</p>
      </li>
    ));
  return (
    <div className={classname}>
      <div className="registrationpart">
        <HeadingWrapper text={heading} />
        <p>
         {text}
        </p>
        <ul className="common-ul">{displayList()}</ul>
      </div>
    </div>
  );
}

export default SecondSectionRegistration;
