import React from "react";
import Layout from "../../layouts/Layout";
import speakerinfo from '../../dummyData/speaker';
import CommonPage from "../../components/common/CommonPage";


function Speaker() {
  return (
    <Layout>
      <CommonPage info={speakerinfo} />
    </Layout>
  );
}

export default Speaker;
