import React from "react";
import MainButton from "../../components/MainButton";
import ababyinnye from "../../assets/compressed/EF-INT-6-min.jpg";

function EventManagementFirstSection({ data, bgimage }) {
  return (
    <div className={`eventsmanagement__first-section `}>
      <div className="superficiallayer">
        <div className="superficiallayer__left">
          <div className="inner">
            <h1>{data.heading}</h1>
            <p>{data.text1}</p>
            <p>{data.text2}</p>
            <div className="button-div">
              <MainButton
                text="Lets handle it for you"
                url="/contact"
                iconName="arrow-right"
              />
            </div>
          </div>
        </div>
        {/* <div className="superficiallayer__right">
          <img src={data.ifoto} alt={data.alt} />
        </div> */}
      </div>

      <div className="background-image">
        <img src={bgimage} alt="ababyinnyi" />
      </div>
    </div>
  );
}

export default EventManagementFirstSection;
