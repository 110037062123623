import React from "react";
import Underline from "./Underline";

function HeadingWrapper({ text, classname }) {
  return (
    <div className={`heading-wrapper ${classname}`}>
      <div>
        <h1 className="heading">{text}</h1>
        <Underline />
      </div>
    </div>
  );
}

export default HeadingWrapper;
