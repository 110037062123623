import React from "react";
import EventManagementFirstSection from "../../pages/EventManagement/EventManagementFirstSection";
import SecondSectionEventManagement from "../../pages/EventManagement/SecondSectionEventManagement";
import FixedBgImage from "./FixedBgImage";

function CommnPageTwo({ data, otherclass, bgimage }) {
  return (
    <div className="eventsmanagement">
      <EventManagementFirstSection bgimage={bgimage} data={data.firstSection} otherclass={otherclass} />
      <SecondSectionEventManagement page={data.page} data={data.secondSection} />
      <FixedBgImage
        classname="eventManagementBgImage"
        text="We make events happen."
      />
    </div>
  );
}

export default CommnPageTwo;
