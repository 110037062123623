import React from "react";
import ServiceCard from "../ServiceCard";
import event from "../../assets/icons/eventmanagement.svg";
import exhibition from "../../assets/icons/exhibition.svg";
import interpretation from "../../assets/icons/microphone.svg";
import online from "../../assets/icons/onlineservice.svg";
import building from "../../assets/icons/3d-buildings.svg";

function Services() {
  return (
    <div className="services">
      <ServiceCard text="Event management" icon={event} url="/events" />
      <ServiceCard
        text="Exhibitions Management"
        url="/exhibition"
        icon={exhibition}
      />
      <ServiceCard
        text="Simultaneous Interpretation Services (SIS)"
        icon={interpretation}
        url="/"
      />
      <ServiceCard
        text="Accommodation Management"
        icon={building}
        url="https://smartbookings.rw/Home/"
        href={true}
      />
      <ServiceCard
        text="Event Management App"
        icon={online}
        url="https://smartevent.rw/"
        href={true}
      />
      <ServiceCard text="Equipment Rental" url="/equipment" icon={exhibition} />
    </div>
  );
}

export default Services;
