import React, { createContext, useState, useEffect } from "react";

export const IntersectionObserverContext = createContext();

const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);
    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);

  return [setRef, visible];
};

function ObserverContextProvider({ children }) {
  const [setRef, visible] = useOnScreen({rootMargin: '0px'});
  const [isSliderVisible, setisSliderVisible] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isSecondTestimonyVisible, setIsSecondTestimonyVisible] = useState(false);
  const [
    isTestimoniesContainerVisible,
    setIsTestimoniesContainerVisible,
  ] = useState(false);

  return (
    <IntersectionObserverContext.Provider
      value={{
        setRef,
        visible,
        isSliderVisible,
        setisSliderVisible,
        isFooterVisible,
        setIsFooterVisible,
        isTestimoniesContainerVisible,
        setIsTestimoniesContainerVisible,
        isSecondTestimonyVisible,
        setIsSecondTestimonyVisible,
      }}
    >
      {children}
    </IntersectionObserverContext.Provider>
  );
}

export default ObserverContextProvider;
