import React, {useContext} from "react";
import { Link } from "react-router-dom";
import logo from "../assets/testimonylogos/Events Factory logo - White (1)-min.png";
import FooterUl from "../components/common/FooterUl";
import services from "../assets/icons/services.svg";
import imigongo from "../assets/images/EF web imigongo patterns-02-min.png";
import SocialIcons from "../components/SocialIcons";

import {IntersectionObserverContext} from '../contexts/intersectionObserverContext';

const data = [
  {
    id: 2,
    heading: "Our services",
    icon: services,
    links: [
      {
        id: 1,
        link: "Event management",
        url: "/events",
      },
      {
        id: 2,
        link: "Digital Events solutions",
        url: "https://smartevent.rw/",
        outside: true,
      },
      {
        id: 3,
        link: "Accommodation",
        url: "https://smartbookings.rw/Home/",
        outside: true,
      },
      {
        id: 4,
        link: "Exhibition Management",
        url: "/exhibition",
      },
      {
        id: 5,
        link: "Simultaneous Interpretation",
        url: "/simulitaneous",
      },
    ],
  },
];

function Footer() {

  const { setRef, visible, setIsFooterVisible } = useContext(
    IntersectionObserverContext
  );

  setIsFooterVisible(visible);

  const displayUls = () =>
    data.map((ul) => (
      <FooterUl
        key={ul.id}
        heading={ul.heading}
        icon={ul.icon}
        links={ul.links}
      />
    ));
  return (
    <footer className="footer">
      <div className="footer-container" ref={setRef}>
        <div className="footer-container__right">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <ul className="company">
            <li>
              <Link to="/about" className="link">
                About us
              </Link>
            </li>
            <li>
              <Link to="/contact" className="link">
                Contact us
              </Link>
            </li>
            <li>
              <a
                href="https://theeventsfactory.biz/webmail"
                target="_blanc"
                className="link"
              >
                Webmail
              </a>
            </li>
          </ul>
          <div style={{ marginLeft: "20px" }}>
            <SocialIcons />
          </div>
        </div>
        <div className="footer-container__left">{displayUls()}</div>
      </div>
      <p className="copy-right">
        © 2020 Events Factory. Treasures Company Ltd. ALL RIGHTS RESERVED.
      </p>
      <div className="footer-lower">
        <img src={imigongo} alt="imigongo" />
        <div className="cover"></div>
      </div>
    </footer>
  );
}

export default Footer;
