import React from "react";
import Layout from "../../layouts/Layout";
import data from "../../dummyData/eventsManagement";
import imigongo from "../../assets/images/EFwebimigongo patterns-03-min.png";
import PlanBox from "../../components/common/PlanBox";
import Package from "../../components/common/Package";

const info = [
  {
    id: 1,
    title: "Single Plan",
    cost: "$10,000",
    list: [
      {
        id: 1,
        text: "Host single event",
      },
      {
        id: 2,
        text: "abstract management and peer review",
      },
      {
        id: 3,
        text: "program & schedule builder",
      },
      {
        id: 4,
        text: "event App(Android & iOs)",
      },
      {
        id: 5,
        text: "ticketing and online payments",
      },
      {
        id: 6,
        text: "Event and session registrations",
      },
      {
        id: 7,
        text: "Event CRM",
      },
      {
        id: 8,
        text: "Polls and surveys",
      },
      {
        id: 9,
        text: "Event website builder",
      },
      {
        id: 10,
        text: "Event analytics and reporting tools",
      },
      {
        id: 11,
        text: "Email and Text marketing tools",
      },
      {
        id: 12,
        text: "Fundraising and donations",
      },
      {
        id: 13,
        text: "Accommodation bookings-REQUEST A QUOTE",
      },
    ],
  },
  {
    id: 2,
    title: "Multiple Plan",
    cost: "$72,000",
    list: [
      {
        id: 1,
        text: "Host Multiple events",
      },
      {
        id: 2,
        text: "Abstract and speaker review",
      },
      {
        id: 3,
        text: "program & schedule builder",
      },
      {
        id: 4,
        text: " Event apps and website",
      },
      {
        id: 5,
        text: "Form builder",
      },
      {
        id: 6,
        text: "Online ticketing and payments",
      },
      {
        id: 7,
        text: "Accommodation booking",
      },
      {
        id: 8,
        text: "Event and workshop registration",
      },
      {
        id: 9,
        text: "Onsite badging and printing",
      },
      {
        id: 10,
        text: "Sponsor and donor  management",
      },
      {
        id: 11,
        text: "Text and email marketing tools",
      },
      {
        id: 12,
        text: "Event analytics and reporting tools",
      },
      {
        id: 13,
        text: "Fundraising and donations",
      },
      {
        id: 14,
        text: "Polls and surveys",
      },
      {
        id: 15,
        text: " Participation certificate builder",
      },
      {
        id: 16,
        text: "Event CRM",
      },
    ],
  },
  {
    id: 3,
    title: "Unlimited events",
    cost: "$100,000",
    list: [
      {
        id: 1,
        text: "Host Multiple events",
      },
      {
        id: 2,
        text: "Abstract and speaker review",
      },
      {
        id: 3,
        text: "program & schedule builder",
      },
      {
        id: 4,
        text: " Event apps and website",
      },
      {
        id: 5,
        text: "Form builder",
      },
      {
        id: 6,
        text: "Online ticketing and payments",
      },
      {
        id: 7,
        text: "Accommodation booking",
      },
      {
        id: 8,
        text: "Event and workshop registration",
      },
      {
        id: 9,
        text: "Onsite badging and printing",
      },
      {
        id: 10,
        text: "Sponsor and donor  management",
      },
      {
        id: 11,
        text: "Text and email marketing tools",
      },
      {
        id: 12,
        text: "Event analytics and reporting tools",
      },
      {
        id: 13,
        text: "Fundraising and donations",
      },
      {
        id: 14,
        text: "Polls and surveys",
      },
      {
        id: 15,
        text: " Participation certificate builder",
      },
      {
        id: 16,
        text: "Event CRM",
      },
    ],
  },
];

const individualPackages = [
  {
    id: 2,
    text: "Abstract and speaker review",
    cost: "$1,000",
  },
  {
    id: 3,
    text: "program & schedule builder",
    cost: "$500",
  },
  {
    id: 4,
    text: " Event apps and website",
    cost: "$5,000",
  },
  {
    id: 5,
    text: "Form builder",
    cost: "$7,000",
  },
  {
    id: 6,
    text: "Online ticketing and payments",
    cost: "$3,000",
  },
  {
    id: 7,
    text: "Accommodation booking",
    cost: "$1,000",
  },
  {
    id: 8,
    text: "Event and workshop registration",
    cost: "$8,000",
  },
  {
    id: 9,
    text: "Onsite badging and printing",
    cost: "$1,200",
  },
  {
    id: 10,
    text: "Sponsor and donor  management",
    cost: "$1,500",
  },
  {
    id: 11,
    text: "Text and email marketing tools",
    cost: "$1,800",
  },
  {
    id: 12,
    text: "Event analytics and reporting tools",
    cost: "$3,000",
  },
  {
    id: 13,
    text: "Fundraising and donations",
    cost: "$1,000",
  },
  {
    id: 14,
    text: "Polls and surveys",
    cost: "$1,000",
  },
  {
    id: 15,
    text: " Participation certificate builder",
    cost: "$1,000",
  },
  {
    id: 16,
    text: "Event CRM",
    cost: "$1,100",
  },
];

function Pricing() {
  const displayPlan = () =>
    info.map((plan) => <PlanBox key={plan.id} data={plan} />);

  const displayPackage = () =>
    individualPackages.map((p) => <Package key={p.id} data={p} />);
  return (
    <Layout>
      <div className="pricing">
        <div className="upper-part">
          <h1> PACKAGES/ PRICING AND TRIAL </h1>
          <p>
            {" "}
            We believe that every event is unique, we let the customer decide on
            what and how their event should be managed.
          </p>
        </div>
        <h1>Choose a plan with us</h1>
        <div className="plans-container">{displayPlan()}</div>
        <p className="question">
          Don’t need the whole thing? Check out our individualized packages{" "}
        </p>
        <div className="individualized-packages">{displayPackage()}</div>
      </div>
    </Layout>
  );
}

export default Pricing;
