import React from "react";

function BurgerBtn({ showMenu, handleshowMenu }) {
  return (
    <div className="burger-button-container">
      <div className="burger-button-container__inner">
        <div
          className="burger-icon"
          style={{ display: `${!showMenu ? "block" : "none"}` }}
          onClick={() => handleshowMenu(true)}
        >
          <i class="fas fa-bars" >
            <p>Menu</p>
          </i>
        </div>
        <div
          className="closing-icon"
          style={{ display: `${showMenu ? "block" : "none"}` }}
          onClick={() => handleshowMenu(false)}
        >
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  );
}

export default BurgerBtn;
