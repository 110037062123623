import dataImage from "../assets/svgs/artmuseum.svg";

import floorplan from "../assets/compressed/EF exh floor plans 1-min.png";
import boothtypes from "../assets/compressed/EF-Exbn-4-min.jpg";
import logisticsAdvisory from "../assets/compressed/EF-exhibition-min.jpg";

export default {
  page: "exhibition",
  firstSection: {
    classname: "commonpage-eventsmanagement",
    heading: "Exhibition Management",
    text1: `Every exhibition is different. We offer services ranging from design layout, exhibitor management, preparation of exhibitor contracts, imports support, exhibition booths buildup and branding.`,
    text2: "",
    ifoto: dataImage,
    alt: "data setup",
  },
  secondSection: [
    {
      id: 1,
      ifoto: floorplan,
      alt: "venue",
      message: `Let's get you organised. We create the layout of the event by securing venues, assigning and partitioning spaces.. We help you create floor plans for your exhibitors and coordinate the bookings.`,
      title: "Venue and floor plans",
      icon: "fas fa-truck-loading",
      link: "/contact",
      more: true,
    },
    {
      id: 2,
      ifoto: boothtypes,
      alt: "booth type",
      message: `We build bespoke standard shell scheme booths. We are there during the buildup and take down and offer branding services.`,
      title: "Booth types",
      icon: "fas fa-door-open",
      link: "/contact",
      more: true,
    },
    {
      id: 3,
      ifoto: logisticsAdvisory,
      alt: "logistics",
      message: `We realize setting up exhibitions can be tasking.With our extensive experience and expertise,we assist with exhibitor contracts,exhibitors manual and offer imports support as well as freight advisory.We are here to guide you through it all.
`,
      title: "Logistics advisory",
      icon: "fas fa-clipboard-check",
      link: "/contact",
      more: true,
    },
  ],
};
