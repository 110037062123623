import React from "react";
import AnimatedCard from "../../components/common/AnimatedCard";
import MainButton from "../../components/MainButton";


function FirstSectionRegistration({ data}) {
  return (
    <div className="registration__first-section">
      <div className="content">
        <h1>{data.heading}</h1>
        <p>
         {data.text}
        </p>
      {
        data.visible ? (
        <div className="register-button-container">
          <MainButton
            text="Register now"
            url={data.url}
            iconName="arrow-right"
            classname="white-bg"
          />
        </div>): ''
      }
      </div>
      <img src={data.ifoto} alt="monitor" />

      {/* <AnimatedCard /> */}
    </div>
  );
}

export default FirstSectionRegistration;
