import React from "react";
import CommonCard from "../../components/common/CommonCard";




function SecondSectionEventManagement({page, data }) {
  const displayCards = () =>
    data.map((card) => (
      <CommonCard
        key={card.id}
        card={card}
        page={page}
      />
    ));


  return (
    <div className="eventsmanagement__second-section">
      {displayCards()}
    </div>
  );
}

export default SecondSectionEventManagement;
