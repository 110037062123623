import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuLi from "../components/MenuLi";
import logo from "../assets/images/redlogo.png";
import logo2 from "../assets/images/redlogo2.png";
import SocialIcons from "../components/SocialIcons";
import BurgerBtn from "../components/BurgerBtn";
import MainLink from "../components/common/MainLink";

// const subMenu = [
//   {
//     id: 1,
//     mainlink: "Digital Events solutions",
//     more: true,
//     submenuColumns: [
//       {
//         id: 1,
//         category: "",
//         subUlLists: [
//           {
//             id: 1,
//             sublink: "Delegate / client Registration",
//             url: "/registration",
//           },
//           {
//             id: 2,
//             sublink: "Delegate / client accreditation",
//             url: "/delegate",
//           },
//           {
//             id: 3,
//             sublink: "Presenter / Speaker management",
//             url: "/speaker",
//           },
//           {
//             id: 4,
//             sublink: "Venue Management",
//             url: "/",
//           },
//         ],
//       },
//     ],
//   },
// ];
const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);
    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);

  return [setRef, visible];
};

function Header() {
  const [showMenu, setshowMenu] = useState(false);
  const [setRef, visible] = useOnScreen({ rootMargin: "-20px" });

  // const displayMenuLis = () =>
  //   subMenu.map((menuLi) => (
  //     <MenuLi
  //       key={menuLi.id}
  //       mainlink={menuLi.mainlink}
  //       submenuColumns={menuLi.submenuColumns}
  //       more={menuLi.more}
  //     />
  //   ));
  return (
    <nav className="navbar">
      <div className="nav-upper-area">
        <Link to="/" ref={setRef} className="logo" id="logo">
          <img src={logo} alt="logo" />
        </Link>
        {/* <div className="nav-social-icons-container">
          <SocialIcons />
        </div> */}
        <div
          className={`menu-container ${showMenu ? "showMenu" : "hideMenu"} ${
            !visible ? "fixMenuTop" : ""
          }`}
          id="nav-main-links"
        >
          {visible ? (
            <ul className="menu-main">
              <MainLink text="Home" link="/" />
              <MainLink text="About us" link="/about" />
              <MainLink text="Event management" link="/events" />
              {/* {displayMenuLis()} */}
              <MainLink
                text="Digital Events solutions"
                link="/"
                outside={true}
                url={`https://smartevent.rw/`}
              />
              <MainLink
                text="Accommodation"
                link="/"
                outside={true}
                url={`https://smartbookings.rw/Home/`}
              />
              <MainLink text="Exhibition Management" link="/exhibition" />
              <MainLink
                text="Simultaneous Interpretation"
                link="/simulitaneous"
              />
              {/* <MainLink text="Pricing & trial" link="/pricing" /> */}
              <MainLink text="Contact us" link="/contact" />
            </ul>
          ) : (
            <ul
              className="menu-main fixMenuTop"
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Link to="/">
                <img
                  src={logo2}
                  alt="logo"
                  height="80px"
                  width="170px"
                  className="logo2"
                />
              </Link>
              <MainLink text="Home" link="/" />
              <MainLink text="About us" link="/about" />
              <MainLink text="Event management" link="/events" />
              {/* {displayMenuLis()} */}
              <MainLink
                text="Digital Events solutions"
                link="/"
                outside={true}
                url={`https://smartevent.rw/`}
              />
              <MainLink
                text="Accommodation"
                link="/"
                outside={true}
                url={`https://smartbookings.rw/Home/`}
              />
              <MainLink text="Exhibition Management" link="/exhibition" />
              <MainLink
                text="Simultaneous interpretation"
                link="/simulitaneous"
              />
              {/* <MainLink text="Pricing & trial" link="/pricing" /> */}
              <MainLink text="Contact us" link="/contact" />
            </ul>
          )}
        </div>
      </div>

      <BurgerBtn showMenu={showMenu} handleshowMenu={setshowMenu} />
    </nav>
  );
}

export default Header;
