import React from "react";

function SocialIcons() {
  return (
    <div className="social-icons">
      <a
        href="https://twitter.com/eventsfactoryrw"
        target="_blanc"
        className="social-icon"
      >
        <i className="fab fa-twitter"></i>
      </a>
      <a
        href="https://www.facebook.com/treasurescompanyltd.biz"
        target="_blanc"
        className="social-icon"
      >
        <i className="fab fa-facebook-f"></i>
      </a>
      <a
        href="https://m.youtube.com/channel/UCBjNUuNsquhm_khq3ceOjWg?disable_polymer=true&itct=IhMI1-j-kJ2x7gIViuLBCh1YxQ4d"
        target="_blanc"
        className="social-icon"
      >
        <i className="fab fa-youtube"></i>
      </a>
      <a
        href="https://www.instagram.com/eventsfa/"
        target="_blanc"
        className="social-icon"
      >
        <i className="fab fa-instagram"></i>
      </a>
    </div>
  );
}

export default SocialIcons;
