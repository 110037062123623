import React from 'react'

function Underline() {
    return (
      <div className="underline-animation-container">
        <div className="underline-circle"></div>
      </div>
    );
}

export default Underline
