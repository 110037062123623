import React from "react";
import Layout from "../../layouts/Layout";
import FirstSectionRegistration from "./FirstSectionRegistration";
import SecondSectionRegistration from "./SecondSectionRegistration";
import ThirdSectionRegistration from "./ThirdSectionRegistration";
import FourthSectionRegistration from "./FourthSectionRegistration";
import paymentOption from "../../assets/svgs/paymentOption.svg";

const firstsectionInfo = {
  text: ` Be it a smoother registration process or engaging your attendees till
          the event day, Events factory eases your task of creating the best event
          experience from beginning to end.`,

  heading: `Delegate and Client Registration`,
  visible: true,
  ifoto: `https://www.broadcastbeat.com/wp-content/uploads/2019/06/DY-IOS-System-monitor-image.png`,
  url: `https://smartbookings.rw/Home/`,

};

const secondSectionInfo = {
  classname: "registration__second-section",
  text: ` Events factory provides a simple & mobile-friendly event registration
          experience that fully aligns with your brand and resonates with your
          attendees, speakers, exhibitors, and sponsors. No design skills
          required.`,
  heading: "Registration & form builder",
  ulList: [
    {
      id: 1,
      text: `Multiple registrations flow based on attendee type`,
    },
    {
      id: 2,
      text: `Self-service options to register, modify, and cancel`,
    },
    {
      id: 3,
      text: `Automated wait lists and approvals`,
    },
    {
      id: 4,
      text: `Customizable registration emails, session info, badges, and reminders`,
    },
  ],
};

const thirdSectionInfo = {
  heading: `Session / workshop registration`,
  text: `
        You don't require a week-long training to get started with our platform.
        Setting up your events is super intuitive and it takes just a few
        minutes to publish a Professional looking event page. Enter your event
        details, registration types, payment options and start accepting online
        registration immediately. Registering online should be a great
        experience for your attendees. Your attendees can easily register
        without having to create an Eventzilla account. Simpler registration
        page converts better.
        `,
};

const registrationFouthSectionInfo = {
  text1: ` Payments are processed securely and sent to your payment processing
          account immediately. No waiting till the event date.`,
  text2: `Supported payment options:`,
  heading:
    "Manage abstracts & proposals submitted by authors, and assign to reviewers for review.",
  ifoto: paymentOption,
  ulList: [
    {
      id: 1,
      text: "Visa card",
      icon: "fab fa-cc-visa",
    },
    {
      id: 2,
      text: "Master card",
      icon: "fab fa-cc-mastercard",
    },
    {
      id: 3,
      text: "Cash",
      icon: "fas fa-money-bill",
    },
    {
      id: 4,
      text: "Mobile money/ Airtel money",
      icon: "fas fa-mobile",
    },
  ],
};
const registrationfifthSectionInfo = {
  heading: `CRM / database management`,
  text: `
        You don't require a week-long training to get started with our platform.
        Setting up your events is super intuitive and it takes just a few
        minutes to publish a Professional looking event page. 
        `,
};
function Registration() {
  return (
    <Layout>
      <div className="registration">
        <FirstSectionRegistration
          data={firstsectionInfo}
        />
        <SecondSectionRegistration
          text={secondSectionInfo.text}
          classname={secondSectionInfo.classname}
          heading={secondSectionInfo.heading}
          ulList={secondSectionInfo.ulList}
        />
        <ThirdSectionRegistration
          title={thirdSectionInfo.heading}
          text={thirdSectionInfo.text}
        />

        <FourthSectionRegistration data={registrationFouthSectionInfo} />
        <ThirdSectionRegistration
          title={registrationfifthSectionInfo.heading}
          text={registrationfifthSectionInfo.text}
        />
      </div>
    </Layout>
  );
}

export default Registration;
