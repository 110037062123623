import dataImage from "../assets/svgs/data.svg";

// photos

import simulitaneous from "../assets/compressed/simulitaneousInterpretation-min.jpg";
import livestreaming from "../assets/compressed/livestreaming.jpg";
import eventmanagement from "../assets/compressed/setup-min.jpg";
import exhibition from "../assets/compressed/exhibition.jpg";
import equipmentRental from "../assets/compressed/Equipment rental-min.png";
import delegate from "../assets/compressed/EF Reg 5-min.jpg";
import accommodation from "../assets/compressed/Serena room.jpg";

export default {
  page: "eventmanagement",
  firstSection: {
    classname: "commonpage-eventsmanagement",
    heading: "Event Management",
    text1: `In our experience, no single event is identical to another, that’s why we treat every event as if it were new and we pay full attention as if building from scratch.
We carefully assess the needs of a clients’ event and offer solutions for its realisation, always with the client’s budget in mind.`,
    text2: ``,
    ifoto: dataImage,
    alt: "data setup",
    link: "/contact",
  },
  secondSection: [
    {
      id: 1,
      ifoto: delegate,
      alt: "delegate",
      message: `We gather the right information from participants and to ensure accuracy in the execution of the event.
We categorize, assist in registration and accreditation and ensure that the right information is passed to them through our different marketing tools like emails and text messaging. 
`,
      title: "Delegate management",
      icon: "fas fa-user-friends",
      link: "#",
      more: false,
    },
    {
      id: 6,
      ifoto: eventmanagement,
      alt: "event management",
      message: `From securing venues and partitioning rooms to finding the right decorators, food providers and entertainment. We have a strong network of service providers ready to ensure the smooth sailing of your events. We offer audio visual production, media management, branding and many more. Let us handle it for you. `,
      title: "Event management",
      icon: "fas fa-truck-loading",
      url: "https://smartevent.rw/",
      out: true,
      more: true,
    },
    {
      id: 2,
      ifoto: accommodation,
      alt: "accommodation",
      message: `Book your delegates the best Rwanda has to offer. Use few clicks and access a list of carefully curated hotels and book any accommodation you wish.`,
      title: "Accommodation management",
      icon: "fas fa-building",
      link: "www.smartbookings.com",
      url: "https://smartbookings.rw/Home/",
      out: true,
      more: true,
    },
    {
      id: 3,
      ifoto: exhibition,
      alt: "Exhibition buildup & breakdown",
      message: `We offer services ranging from layout design, exhibitor management, preparation of exhibition contracta, importation support, exhibition booth buildup, and branding. We build standard shell scheme booths as well as bespoke booths. We also have an exhaustive range of event rental items to help cover the event.`,
      title: "Exhibition",
      icon: "fas fa-camera-retro",
      link: "/exhibition",
      more: true,
    },
    {
      id: 4,
      ifoto: simulitaneous,
      alt: "simultaneous interpretation",
      message: `We have in-house quality simultaneous interpretation equipment that ensures no radio interferences during meetings. We have also put together a team of accredited interpreters who will handle the job at hand in all international languages`,
      title: "simultaneous interpretation",
      icon: "fas fa-headset",
      link: "/simulitaneous",
      more: true,
    },
    {
      id: 7,
      ifoto: livestreaming,
      alt: "Live streaming",
      message: `The world is going digital and we are not being left behind. We have digital solutions that allow us handle all the online problems
We provide online services to help create a seamless delegate experience.`,
      title: "Live streaming",
      icon: "fas fa-video",
      link: "/contact",
      more: true,
    },
    {
      id: 5,
      ifoto: equipmentRental,
      alt: "Equipment rental",
      message: `Along with our skill base, we own a list of rental, simultaneous interpretation equipment, exhibition building equipment and more. We are constantly investing and updating it which enables us to deliver events to the highest possible standard.`,
      title: "Equipment rental",
      icon: "fas fa-shuttle-van",
      link: "/equipment",
      more: true,
    },
  ],
};
