import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ObserverContextProvider from "./contexts/intersectionObserverContext";
import About from "./pages/About/About";
import ContactUs from "./pages/Contact/Contact";
import Equipment from "./pages/Equipment/Equipment";
import EventsManagement from "./pages/EventManagement/EventsManagement";
import Exhibition from "./pages/Exhibition/Exhibition";
import Home from "./pages/Home/Home";
import Pricing from "./pages/Pricing/Pricing";
import Registration from "./pages/Registration/Registration";
import Simulitaneous from "./pages/Simulitaneous/Simulitaneous";
import Speaker from "./pages/SpeakerManagement/Speaker";

const renderedComponents = [
  {
    id: 1,
    path: "/",
    component: Home,
    exact: true,
  },
  {
    id: 2,
    path: "/about",
    component: About,
    exact: false,
  },
  {
    id: 3,
    path: "/events",
    component: EventsManagement,
    exact: false,
  },
  {
    id: 4,
    path: "/registration",
    component: Registration,
    exact: false,
  },
  {
    id: 5,
    path: "/speaker",
    component: Speaker,
    exact: false,
  },
  {
    id: 6,
    path: "/equipment",
    component: Equipment,
    exact: false,
  },
  {
    id: 7,
    path: "/exhibition",
    component: Exhibition,
    exact: false,
  },
  {
    id: 8,
    path: "/simulitaneous",
    component: Simulitaneous,
    exact: false,
  },
  {
    id: 9,
    path: "/pricing",
    component: Pricing,
    exact: false,
  },
  {
    id: 10,
    path: "/contact",
    component: ContactUs,
    exact: false,
  },
];

const App = () => (
  <ObserverContextProvider>
    <Router>
      <>
        {renderedComponents.map((route) => (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            render={(props) => <route.component {...props} />}
          />
        ))}
      </>
    </Router>
  </ObserverContextProvider>
);

export default App;
