import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

function TradePopUp({ children, openPopup, setTradeOpenPopup }) {
  return (
    <Dialog open={openPopup}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{ fontWeight: "700", fontSize: "18px" }}
            className="popupheading"
          >
            Some of the trade shows we organized
          </div>
          {/* <i
            class="fas fa-times"
            onClick={() => setTradeOpenPopup(false)}
            style={{ fontSize: "18px", cursor: "pointer" }}
          ></i> */}
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
// TradePopUp

export default TradePopUp;
