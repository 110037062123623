import React from "react";
import { Link } from "react-router-dom";
import Underline from "./Underline";
import IconContainer from "./IconContainer";

function FooterUl({ links, heading, icon }) {
  const displayLinks = () =>
    links.map((link) => (
      <li key={link.id}>
        {link.outside ? (
          <a href={link.url} target="_blanc" className="link linkborder">
            {link.link}
          </a>
        ) : (
          <Link to={link.url} className="link linkborder">
            {link.link}
          </Link>
        )}
      </li>
    ));
  return (
    <ul>
      <div className="heading-box">
        {/* <IconContainer icon={icon} /> */}
        <p>{heading}</p>
      </div>
      <Underline />
      <div className="links">{displayLinks()}</div>
    </ul>
  );
}

export default FooterUl;
