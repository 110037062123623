import React from "react";
import { Link } from "react-router-dom";

// Pricing
function CommonCard({ card }) {


  if (card.out) {

    return (
      <a className="boxlink commoncard__outer" href={card.url} target="_blanc">
        <div className="commoncard__inner">
          <img loading="lazy" src={card.ifoto} alt={card.alt} />
          <div className="content-wrapper">
            <div className="titlepart">
              <i class={`${card.icon}`}></i>
              <p>{card.title}</p>
            </div>
            <div className="detailspart">
              <p>{card.message}</p>
            </div>
            {card.more ? <i class="fas fa-arrow-right"></i> : ""}
          </div>
        </div>
      </a>
    );
  } else {
    return (
      <Link className="boxlink commoncard__outer" to={card.link}>
        <div className="commoncard__inner">
          <img loading="lazy" src={card.ifoto} alt={card.alt} />
          <div className="content-wrapper">
            <div className="titlepart">
              <i class={`${card.icon}`}></i>
              <p>{card.title}</p>
            </div>
            <div className="detailspart">
              <p>{card.message}</p>
            </div>
            {card.more ? <i class="fas fa-arrow-right"></i> : ""}
          </div>
        </div>
      </Link>
    );
  }
}

export default CommonCard;
