import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => (
  <div style={{ minHeight: "100vh" }}>
    <Header />

    <main>{children}</main>

    <Footer />
  </div>
);

export default Layout;
