import React from "react";

function CircleImage({ profile }) {
  return (
    <div className="img-section">
      <img src={profile} alt="profile" />
    </div>
  );
}

export default CircleImage;
