import React from "react";
import Layout from "../../layouts/Layout";
import CommnPageTwo from "../../components/common/CommnPageTwo";
import data from "../../dummyData/eventsManagement";
import ifoto from "../../assets/compressed/EF-OESAI-1-min.jpg";

function EventsManagement() {
  return (
    <Layout>
      <CommnPageTwo data={data} bgimage={ifoto} />
    </Layout>
  );
}

export default EventsManagement;
