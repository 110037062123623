import React from 'react'

function Package({data}) {
    return (
      <div className="individual-package">
        <p className="title">{data.text}</p>
        <div className="plan-box-footer">
          <h1>{data.cost}</h1>
        </div>
      </div>
    );
}

export default Package
