import React from "react";
import CommnPageTwo from "../../components/common/CommnPageTwo";
import Layout from "../../layouts/Layout";
import data from "../../dummyData/simulitaneousinterpretation";
import bgimage from "../../assets/compressed/simulitaneousInterpretation-min.jpg";

function Simulitaneous() {
  return (
    <Layout>
      <CommnPageTwo data={data} bgimage={bgimage} />
    </Layout>
  );
}

export default Simulitaneous;
