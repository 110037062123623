import React from "react";
import { Link } from "react-router-dom";
import IconContainer from "./common/IconContainer";

function ServiceCard({ icon, text, url, href }) {
  return (
    <div className="service">
      {href ? (
        <a className="link" href={url} target="_blanch">
          <IconContainer icon={icon} />
          <p>{text}</p>
        </a>
      ) : (
        <Link className="link" to={url}>
          <IconContainer icon={icon} />
          <p>{text}</p>
        </Link>
      )}
    </div>
  );
}

export default ServiceCard;
