import paymentOption from "../assets/svgs/paymentOption.svg";
const ifotoo =
  "https://www.touchway.com/data/docs/en/3094/Concierge-Registration-Admin.svg?v=1.0";
const ifoto = `https://www.prevuemeetings.com/wp-content/uploads/2016/09/ALICE-Staff.png`;

export default {
  firstSection: {
    text: `Collect proposals & talks with simple & complex submission workflows, assign submissions to reviewers for evaluation and make decision using bulk acceptance/rejection tool. Save time with automated email notifications for notifying authors & reviewers.`,
    heading: `Abstract Management & Peer Review System`,
    visible: false,
    ifoto: `https://www.joomlashowroom.com/images/products/event-schedule-pro/Event-Schedule_Pro-Responsive-presentation.jpg`,
  },
  secondSection: {
    classname: "speaker__second-section",
    text: ` Your abstract submission software is completely configurable with options including:`,
    heading:
      "Several configurable options to set up your abstract submissions and review system.",
    ifoto: ifotoo,
    ulList: [
      {
        id: 1,
        text: `set abstract submission start and end dates`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: `set abstract summary word limit`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: `maximum co-authors per abstract`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: `maximum submissions intake`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 5,
        text: `enable/disable double blind review, and many more.`,
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
  thirdSection: {
    heading: "Presentation upload and review",
    ifoto: paymentOption,
    ulList: [
      {
        id: 1,
        text:
          "set abstract status to accept/reject and notify authors and co-authors automatically",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "export abstracts to CSV & PDF",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "filter abstracts by custom data points",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "sort by abstract rating and abstract status",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
  fourthSection: {
    heading: "CRM / Speaker accreditation",
    alt: "speaker",
    ifoto: ifoto,
    ulList: [
      {
        id: 1,
        text:
          "set abstract status to accept/reject and notify authors and co-authors automatically",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "export abstracts to CSV & PDF",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "We make events happen and realized everything.",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "sort by abstract rating and abstract status",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },

  fifthSection: {
    classname: "speaker__second-section",
    text: ` Your abstract submission software is completely configurable with options including:`,
    heading: "This is a different story ",
    ifoto: ifotoo,
    ulList: [
      {
        id: 1,
        text: `set abstract submission start and end dates`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: `set abstract summary word limit`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: `maximum co-authors per abstract`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: `maximum submissions intake`,
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 5,
        text: `we are different from the rest.`,
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },

  sixthSection: {
    heading: "Registration and others",
    alt: "registration image",
    ifoto: ifoto,
    ulList: [
      {
        id: 1,
        text:
          "set abstract status to accept/reject and notify authors and co-authors automatically",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 2,
        text: "export abstracts to CSV & PDF",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 3,
        text: "We make events happen and realized everything.",
        icon: "fas fa-chevron-circle-right",
      },
      {
        id: 4,
        text: "sort by abstract rating and abstract status",
        icon: "fas fa-chevron-circle-right",
      },
    ],
  },
};
