import React, { useContext } from "react";
import MainButton from "./MainButton";

import { IntersectionObserverContext } from "../contexts/intersectionObserverContext";

function SliderContainer({ data }) {
  const { setRef, visible, setisSliderVisible } = useContext(
    IntersectionObserverContext
  );

  setisSliderVisible(visible);
  return (
    <div className="slider-container">
      <img src={data.ifoto} alt="events factory" />
      <div className="font-layer">
        <div className="inner" ref={setRef}>
          <h1>{data.title}</h1>
          <p>{data.text}</p>
          <div className="button-div">
            <MainButton
              text={data.button}
              iconName="arrow-right"
              url={data.url}
              out={data.out}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderContainer;
