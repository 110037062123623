import React from "react";
import CompanyLogos from "../CompanyLogos";

function FixedBgImage({ classname, text }) {
  return (
    <div className="images">
      <div className={classname}>
        <div className="inner">
          <h1>Our partners</h1>
          <CompanyLogos />
        </div>
      </div>
    </div>
  );
}

export default FixedBgImage;
