import React from "react";
import HeadingWrapper from "../../components/common/HeadingWrapper";

function FourthSectionRegistration({
  data,
}) {
  const displayList = () =>
    data.ulList.map((list) => (
      <li key={list.id}>
        <i class={list.icon}></i>
        <p>{list.text}</p>
      </li>
    ));

  return (
    <div className="registration__fourth-section">
      <div className="left">
        <img src={data.ifoto} alt="payment option svg" />
      </div>
      <div className="right">
        <div>
          <HeadingWrapper className="uncenteredHeading" text={data.heading} />
          <p>{data.text1}</p>
          <p>{data.text2}</p>
          <ul>{displayList()}</ul>
        </div>
      </div>
    </div>
  );
}

export default FourthSectionRegistration;
