import React from "react";
import { Link, NavLink } from "react-router-dom";

function MainLink({ link, text, outside, url }) {
  return (
    <li className="li-main">
      {outside ? (
        <a href={url} target="_blanc" className="link">
          {text}
        </a>
      ) : (
        <NavLink
          exact
          activeClassName="main-nav-active"
          className="link"
          to={`${link}`}
        >
          {text}
        </NavLink>
      )}
    </li>
  );
}

export default MainLink;
