import React from "react";
import fanaf from "../assets/testimonylogos/FANAF Logo-min.jpg";
import redisonblue from "../assets/testimonylogos/Kigali_Radisson-Colour-350x123-2-min.png";
import aims from "../assets/testimonylogos/AIMS logo-min.jpg";
import CLDP from "../assets/testimonylogos/CLDP logo-min.png";
import who from "../assets/testimonylogos/Logo-WHO1-min.jpg";
import psf from "../assets/testimonylogos/PSF-LOGO-FULL-HD-min.png";
import nationalPolice from "../assets/testimonylogos/Rwanda_National_Police-min.png";
import smart from "../assets/testimonylogos/Smart-1-min.png";
import twas from "../assets/testimonylogos/TWAS logo-min.png";
import ucsf from "../assets/testimonylogos/UCSF Logo-min.jpg";
import university from "../assets/testimonylogos/university_logo_small_vertical_blue-min.png";
import oesai from "../assets/testimonylogos/OESAI-logo-min.jpg";
import unitycombate from "../assets/testimonylogos/Uniting to Combat-min.jpeg";
import afca from "../assets/testimonylogos/AFCA-min.png";

const pooltry =
  "https://poultryafricaevent.com/wp-content/uploads/2020/10/Poultry_africa_logotype_show_no-text.png";

const data = [
  {
    id: 0,
    logo: `https://www.gov.rw/fileadmin/gov/resources/public/images/Coat_of_arms_of_Rwanda.svg`,
    alt: "govenment of Rwanda",
  },
  {
    id: 13,
    logo: psf,
    alt: "psf",
  },
  {
    id: 11,
    logo:
      "https://upload.wikimedia.org/wikipedia/en/thumb/d/d9/INTERPOL_Logo.svg/1200px-INTERPOL_Logo.svg.png",
    alt: "interpol",
  },
  {
    id: 22,
    logo: nationalPolice,
    alt: " national police",
  },
  {
    id: 15,
    logo: twas,
    alt: "TWAS",
  },

  {
    id: 6,
    logo: `https://www.geopoll.com/wp-content/uploads/2017/12/bank-of-kigali-logo.jpg`,
    alt: "Bank of kigali",
  },
  {
    id: 25,
    logo: "https://www.gavi.org/sites/default/files/2019-11/bmgf-topspace.jpg",
    alt: "Bill gates",
  },

  {
    id: 21,
    logo:
      "https://armacad.info/images/2017/05/UnitedNationsDevelopmentProgrammeUNDP-nUPVMSTXPdV8L1lO4-BrSMcvUhN_tuFx.gif",
    alt: "Undp",
  },
  {
    id: 24,
    logo: who,
    alt: "WHO",
  },
  {
    id: 12,
    logo:
      "https://www.innovationlab.my/yourimpact/wp-content/uploads/2013/10/Unicef-logo-vector.png",
    alt: "unicef",
  },
  {
    id: 31,
    logo:
      "https://i.pinimg.com/originals/9d/58/b9/9d58b97f34a0acc10a99d8827a17bf46.jpg",
    alt: "unite to end violance against women",
  },
  {
    id: 10,
    logo: CLDP,
    alt: "CLDP",
  },
  {
    id: 19,
    logo:
      "https://blog-pfm.imf.org/.a/6a00e54ef0059588340224df2e2d80200b-800wi",
    alt: "USAID",
  },
  {
    id: 14,
    logo: smart,
    alt: "SMART AFRICA",
  },

  {
    id: 20,
    logo:
      "https://lebambougorillalodge.com/wp-content/uploads/2015/03/pub-window-1.jpg",
    alt: "RDB",
  },
  {
    id: 18,
    logo:
      "https://cdn-az.allevents.in/banners/287ed29c797ff63e8ee4b37bca30fdcc-rimg-w200-h200-gmir.jpg",
    alt: " RCB",
  },
  {
    id: 17,
    logo: university,
    alt: "university",
  },
  {
    id: 9,
    logo: aims,
    alt: "aims",
  },
  {
    id: 16,
    logo: ucsf,
    alt: "ucsf",
  },

  {
    id: 26,
    logo: oesai,
    alt: "OESAI",
  },
  {
    id: 30,
    logo: "https://www.kcc.rw/uploads/9/8/2/4/98249186/logo_31.png",
    alt: "KCC",
  },
  {
    id: 8,
    logo: redisonblue,
    alt: "redison blue",
  },
  {
    id: 1,
    logo: `https://marriottnews.brightspotcdn.com/dims4/default/3388702/2147483647/strip/true/crop/400x400+0+0/resize/400x400!/quality/90/?url=https%3A%2F%2Fmarriottnews.brightspotcdn.com%2Fd2%2F8b%2F45fd97be43479d338f471809a9c2%2Flogo-marriott.png`,
    alt: "marriot hotel",
  },

  {
    id: 3,
    logo: `https://www.winne.com/country/ssa/rwanda/cp/2015/kigali-serena-hotel/kigali-serena-hotel-logo.jpg`,
    alt: "Kigali serena hotel",
  },

  {
    id: 7,
    logo: fanaf,
    alt: "Fanaf",
  },

  {
    id: 28,
    logo: afca,
    alt: "afca",
  },
  {
    id: 29,
    logo: pooltry,
    alt: "pooltry africa",
  },
  {
    id: 27,
    logo: unitycombate,
    alt: "uniting to combat",
  },
];

function CompanyLogos() {
  const displayLogos = () =>
    data.map((logo) => (
      <div key={logo.id} className="company-logo">
        <img src={logo.logo} alt={logo.alt} />
      </div>
    ));
  return <div className="companies-logos-container">{displayLogos()}</div>;
}

export default CompanyLogos;
