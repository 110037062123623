import React from "react";
import { Link } from "react-router-dom";

function MainButton({ iconName, text, url, out }) {
  return (
    <button className="main-btn white-bg">
      {out ? (
        <a href={url} target="_blanc">
          {text}
        </a>
      ) : (
        <Link to={url}>{text}</Link>
      )}
      <i className={`fas fa-${iconName}`}></i>
    </button>
  );
}

export default MainButton;
