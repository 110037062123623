import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

function Launches({ children, openPopup, setLauchOpenPopup }) {
  return (
    <Dialog open={openPopup}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{ fontWeight: "700", fontSize: "18px" }}
            className="popupheading"
          >
            Some of the launches we organized
          </div>
          {/* <i
            class="fas fa-times"
            onClick={() => setLauchOpenPopup(false)}
            style={{ fontSize: "18px", cursor: "pointer" }}
          ></i> */}
        </div>
      </DialogTitle>
      <DialogContent onMouseLeave={() => setLauchOpenPopup(false)} dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default Launches;
