import React, { useState } from "react";
import Underline from "../../components/common/Underline";
import Layout from "../../layouts/Layout";
import AboutSecondSection from "./SecondSection";
import AboutThirdSection from "./AboutThirdSection";
import bgphoto from "../../assets/compressed/EF-ICFP-1-min.jpg";
// icons
import AboutFourthSection from "./AboutFourthSection";
import ConferencesPopUp from "../../components/common/Popup";
import ExhibitionPopUp from "../../components/Exhibition";
import LaunchPopUp from "../../components/Launches";
import SportPopUp from "../../components/sportsPopup";
import TradePopUp from "../../components/TradePopUp";

const conferences = [
  {
    id: 2,
    item: `Africa Leadership Meeting (During ICASA) 2nd December 2019`,
  },
  {
    id: 3,
    item: `International Cooperative Alliance Conference (ICA) (14th -17th October 2019)`,
  },
  {
    id: 4,
    item: `2nd Poultry Africa (2nd - 3rd October 2019)`,
  },
  {
    id: 5,
    item: `AFI Global Policy Forum (11th - 13th September 2019)`,
  },
  {
    id: 6,
    item: `The 42 Association of African Central Banks (AACB) Annual Meeting (28 July - 1 2019)`,
  },
  {
    id: 6,
    item: `The 17th African Fine Coffee Conference & Exhibition (AFCA) (12th – 15th Feb 2019)`,
  },
  {
    id: 7,
    item: `The 24th INTERPOL African Regional Conference (31st Jan – 7th Feb 2019)`,
  },
  {
    id: 8,
    item: `The International Conference on Family Planning (ICFP) (12th – 15th Nov, 2018)`,
  },

  {
    id: 9,
    item: `The 1st WHO Africa Health Forum, (27th - 28th June 2017)`,
  },

  {
    id: 11,
    item: `The 3 Transform Africa Summit, (10 -12 May 2017)`,
  },
  {
    id: 12,
    item: `The 24th World academy of Science (TWAS) General Meeting (14th – 16th 2016)`,
  },

  {
    id: 23,
    item: `UNECA (High Level Regional Event) Meeting on Regional Integration, Sub Region agricultural commodity fair.`,
  },
  {
    id: 24,
    item: `US Government (Department of Trade) High Level: Regional Conference on the role of the judiciary in the protection & enforcement of Intellectual Property Rights in East Africa.`,
  },
];

const launchevents = [
  {
    id: 1,
    item:
      "South African Airlines: - Corporate launch in Kigali and Bujumbura (2012)",
  },
  {
    id: 2,
    item: "Bank of Kigali: Launch of new corporate headquarters",
  },
  {
    id: 3,
    item: "Inyange Industries: Launch of Masaka Plant and Head Office",
  },
  {
    id: 4,
    item:
      "East African Granite Industries: Launch of Nyagatare Plant and Offices",
  },
  {
    id: 5,
    item: "NPD-Cotraco: Launch of MTN-Center Nyarutarama",
  },
  {
    id: 6,
    item: "Nile Basin Initiative: Launch of Rusumo Power project",
  },
];

const sportevents = [
  {
    id: 1,
    item: "FERWAFA: U-15 FERWAFA Youth League Launch 11th - 22nd Feb (2015)",
  },
  {
    id: 2,
    item:
      "FIFA: Cours de FIFA des Directeurs Techniques 25th – 29th November 2019",
  },
];

const exhibitions = [
  {
    id: 1,
    item: "84th INTERPOL AGA Exhibition",
  },
  {
    id: 2,
    item: "15th Juakali / Nguvu Kazi Regional Exhibition (2014)",
  },
  {
    id: 3,
    item: "Inaugural Cup of Excellence Competition & Expo (2008)",
  },
  {
    id: 4,
    item: "The Second Africa Tea Exhibition",
  },
  {
    id: 5,
    item: "Rwanda International Trade Fairs 2007",
  },
];

const PopupConferencesContent = () => {
  const displayConferences = () =>
    conferences.map((conference) => (
      <li key={conference.id}>
        <p>{conference.item}</p>
      </li>
    ));
  return (
    <div className="conferences">
      <ul>{displayConferences()}</ul>
    </div>
  );
};
const PopupExhibitionContent = () => {
  const displayExhibition = () =>
    exhibitions.map((exhibition) => (
      <li key={exhibition.id}>
        <p>{exhibition.item}</p>
      </li>
    ));
  return (
    <div className="conferences">
      <ul>{displayExhibition()}</ul>
    </div>
  );
};

const PopupTradeContent = () => {
  const displayConferences = () =>
    exhibitions.map((conference) => (
      <li key={conference.id}>
        <p>{conference.item}</p>
      </li>
    ));
  return (
    <div className="conferences">
      <ul>{displayConferences()}</ul>
    </div>
  );
};

const PopuplaunchesContent = () => {
  const displayConferences = () =>
    launchevents.map((conference) => (
      <li key={conference.id}>
        <p>{conference.item}</p>
      </li>
    ));
  return (
    <div className="conferences">
      <ul>{displayConferences()}</ul>
    </div>
  );
};

const PopupsportsContent = () => {
  const displayConferences = () =>
    sportevents.map((sportevent) => (
      <li key={sportevent.id}>
        <p>{sportevent.item}</p>
      </li>
    ));
  return (
    <div className="conferences">
      <ul>{displayConferences()}</ul>
    </div>
  );
};

function About() {
  const [conferenceOpenPopup, setconferenceOpenPopup] = useState(false);
  const [exhibitionOpenPopup, setExhibitionOpenPopup] = useState(false);
  const [lauchOpenPopup, setLauchOpenPopup] = useState(false);
  const [sportsOpenPopup, setSportsOpenPopup] = useState(false);
  const [tradeOpenPopup, setTradeOpenPopup] = useState(false);

  return (
    <Layout>
      <div className="aboutpage">
        <div className="aboutpage__first-section">
          <div className="superficial">
            <h1>Our aim is to get it right the first time.</h1>
            <Underline />
          </div>
          <img src={bgphoto} alt="EF-ICFP" />
        </div>
        <AboutSecondSection />
        <AboutThirdSection />
        <AboutFourthSection
          setExhibitionOpenPopup={setExhibitionOpenPopup}
          setconferenceOpenPopup={setconferenceOpenPopup}
          setLauchOpenPopup={setLauchOpenPopup}
          setSportsOpenPopup={setSportsOpenPopup}
          setTradeOpenPopup={setTradeOpenPopup}
        />
      </div>
      <ConferencesPopUp
        openPopup={conferenceOpenPopup}
        setconferenceOpenPopup={setconferenceOpenPopup}
      >
        <PopupConferencesContent />
      </ConferencesPopUp>
      <ExhibitionPopUp
        openPopup={exhibitionOpenPopup}
        setExhibitionOpenPopup={setExhibitionOpenPopup}
      >
        <PopupExhibitionContent />
      </ExhibitionPopUp>

      <LaunchPopUp
        openPopup={lauchOpenPopup}
        setLauchOpenPopup={setLauchOpenPopup}
      >
        <PopuplaunchesContent />
      </LaunchPopUp>

      <SportPopUp
        openPopup={sportsOpenPopup}
        setSportsOpenPopup={setSportsOpenPopup}
      >
        <PopupsportsContent />
      </SportPopUp>
      <TradePopUp
        openPopup={tradeOpenPopup}
        setTradeOpenPopup={setTradeOpenPopup}
      ></TradePopUp>
    </Layout>
  );
}

export default About;

// Launches
