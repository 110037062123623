import React, { useState, useContext } from "react";
import Layout from "../../layouts/Layout";

import { IntersectionObserverContext } from "../../contexts/intersectionObserverContext";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SliderContainer from "../../components/SliderContainer";
import TestimonyCard from "../../components/TestimonyCard";
import Services from "../../components/common/Services";

// slides

import slider1 from "../../assets/compressed/slide1.jpg";
import slide3 from "../../assets/compressed/EF-EDP-3-min.jpg";
import slide2 from "../../assets/compressed/slide2.jpg";
import slide4 from "../../assets/compressed/EF-Exbn-8-min.jpg";

// testimonies logos

import aims from "../../assets/testimonylogos/AIMS logo-min.jpg";
import CLDP from "../../assets/testimonylogos/CLDP logo-min.png";
import MINICOM from "../../assets/testimonylogos/MINICOM logo-min.png";
import TWAS from "../../assets/testimonylogos/TWAS logo-min.png";
import CompanyLogos from "../../components/CompanyLogos";
import FixedBgImage from "../../components/common/FixedBgImage";

const slides = [
  {
    id: 1,
    title: ` Welcome to Events Factory`,
    text: `We are a full service events management company who assess the needs of our clients and offer befitting solutions.`,
    ifoto: slider1,
    button: "Find out more",
    url: "/about",
  },
  {
    id: 2,
    title: `Are you planning an event? Get started with us.`,
    text: `A smarter way to manage event registration, delegate accommodation, event programs and much more.`,
    ifoto: slide2,
    button: "Get started",
    url: "https://smartevent.rw/",
    out: true,
  },
  {
    id: 3,
    title: `Hybrid Events made easy`,
    text: `Wherever you are, do not miss your important events.`,
    ifoto: slide3,
    button: "Get started",
    url: "https://smartevent.rw/",
    out: true,
  },
  {
    id: 4,
    title: `Exhibition Management`,
    text: `From layout design, exhibitor management, importation support, exhibition buildup and branding, we got you.`,
    ifoto: slide4,
    button: "Find out more",
    url: "/exhibition",
  },
];

const testimonies = [
  {
    id: 1,
    name: "Thierry Zomahoun",
    position: `AIMS President & CEO`,
    text: `“We are happy to note that they executed their duties with diligence, on time and in sometimes stressful conditions. They displayed resilience and were always ready to listen and adapt to the changing circumstances. We attribute the success of our event to the professionalism of Events Factory.”`,
    ifoto: aims,
  },
  {
    id: 2,
    name: "Steven D. Gardner",
    position: `Chief Counsel, CLDP`,
    text: `“Throughout the program, Mr Makuza demonstrated the ability to think outside the box in order to provide us solutions to our logistics challenges. He and his team have shown the ability to meet the tight deadline while maintaining their professionalism and positive attitude, while keeping track of every detail of the event.”`,
    ifoto: CLDP,
    last: true,
  },
  {
    id: 3,
    name: "Emmanuel Hategeka",
    position: `Permanent Secretary, MINICOM`,
    text: `“They have shown time and again their prowess in pulling off events when we sometimes thought it was impossible thanks to their unlimited network of service providers.
”`,
    ifoto: MINICOM,
  },
  {
    id: 4,
    name: "Dag Harald Johannessen",
    position: `Administrative Officer, TWAS`,
    text: `“Their duties covered the entire spectrum of working closely with our Rwandan partners, supporting international participants, coordination of ground logistics, hotel bookings, branding to mention but a few. We are glad to state that they performed their mission with zeal and professionalism. They are a highly conscientious and flexible team able to adapt to fast changing situations.”`,
    ifoto: TWAS,
  },
];

const Home = () => {
  const {
    setRef,
    visible,

    isTestimoniesContainerVisible,
    setIsTestimoniesContainerVisible,
  } = useContext(IntersectionObserverContext);

  const [showArrow, setShowArrow] = useState(false);

  console.log("showArrow", showArrow);

  setIsTestimoniesContainerVisible(visible);

  const displaySlides = () =>
    slides.map((slide) => <SliderContainer data={slide} key={slide.id} />);

  const displayTestimonies = () =>
    testimonies.map((testimony) => (
      <TestimonyCard
        // setIsVisible={setIsVisible}
        setShowArrow={setShowArrow}
        key={testimony.id}
        data={testimony}
      />
    ));

  // console.log("isVisible =====", isVisible);

  return (
    <Layout>
      <div className="home-page">
        <Carousel
          showThumbs={false}
          showArrows
          swipeable
          showStatus={false}
          autoPlay
          infiniteLoop
          useKeyboardArrows
          dynamicHeight
        >
          {displaySlides()}
        </Carousel>

        <div className="home-second-section">
          <div className="left">
            <h1 className="heading">What others say about us</h1>
            <div className="testimonies-container" ref={setRef}>
              {displayTestimonies()}
              {/* {!isTestimoniesContainerVisible ? (
                <a href="#last-testimony" className="direction-arrows">
                  <i class="fas fa-arrow-down"></i>
                </a>
              ) : (
                <div></div>
              )} */}
            </div>
          </div>
          <div className="right">
            <div>
              <Services />
            </div>
          </div>
        </div>
        <div className="home-partners">
          <h1>Our partners</h1>
          <CompanyLogos />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
