import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

function SportPopUp({ children, openPopup, setSportsOpenPopup }) {
  return (
    <Dialog open={openPopup}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{ fontWeight: "700", fontSize: "18px", color: "#be1622" }}
            className="popupheading"
          >
            Some of the sport events we organized
          </div>
          {/* <i
            class="fas fa-times"
            onClick={() => setSportsOpenPopup(false)}
            style={{ fontSize: "18px", cursor: "pointer" }}
          ></i> */}
        </div>
      </DialogTitle>
      <DialogContent onMouseLeave={() => setSportsOpenPopup(false)} dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}
// TradePopUp

export default SportPopUp;
