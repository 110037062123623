import React from "react";
import CircleImage from "./CircleImage";

function ContactBox({ data }) {
  const displayLinks = () =>
    data.info.map((info) => (
      <div className="info" key={info.id}>
        <div className="icon-container">
          <i class={info.icon}></i>
        </div>
        <p>{info.name}</p>
      </div>
    ));
  return (
    <div className="contactbox">
      <CircleImage profile={data.profile} />
      <h1>{data.name}</h1>
      <h3>{data.position}</h3>
      <div className="info-container">{displayLinks()}</div>
      
    </div>
  );
}

export default ContactBox;
