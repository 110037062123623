import React from "react";
import conference from "../../assets/icons/speaker.svg";
import delegate from "../../assets/icons/delegates.svg";
import workshop from "../../assets/icons/workshop.svg";
import trade from "../../assets/icons/tradeshow.svg";
import sports from "../../assets/icons/sports.svg";
import launches from "../../assets/icons/inauguration.svg";
import exhibition from "../../assets/icons/exhibition (1).svg";
import PortifolioCard from "./PortifolioCard";
import FixedBgImage from "../../components/common/FixedBgImage";

const conferences = {
  id: 1,
  text: "International Conferences",
  icon: conference,
  numbers: "80+",
  clickable: true,
};
const delegates = {
  id: 2,
  text: "Delegates",
  icon: delegate,
  numbers: "50,000+",
  clickable: false,
};
const workshops = {
  id: 3,
  text: "Workshops",
  icon: workshop,
  numbers: "30+",
  clickable: false,
};
const trades = {
  id: 4,
  text: "Trade shows",
  icon: trade,
  numbers: "8",
  clickable: false,
};

const sportsevents = {
  id: 5,
  text: "Sports events",
  icon: sports,
  numbers: "2",
  clickable: true,
};

const launcheslist = {
  id: 6,
  text: "Launches",
  icon: launches,
  numbers: "10+",
  clickable: true,
};

const exhibitionscard = {
  id: 7,
  text: "Exhibitions",
  icon: exhibition,
  numbers: "20+",
  clickable: true,
};

// const data = [
//   {
//     id: 1,
//     text: "International Conferences",
//     icon: conference,
//     numbers: "50+",
//     clickable: true,
//   },
//   {
//     id: 2,
//     text: "Delegates",
//     icon: delegate,
//     numbers: "15,000+",
//     clickable: false,
//   },
//   {
//     id: 3,
//     text: "Workshops",
//     icon: workshop,
//     numbers: "20+",
//     clickable: false,
//   },
//   {
//     id: 4,
//     text: "Trade shows",
//     icon: trade,
//     numbers: "8",
//     clickable: false,
//   },
//   {
//     id: 5,
//     text: "Sports events",
//     icon: sports,
//     numbers: "2",
//     clickable: true,
//   },
//   {
//     id: 6,
//     text: "Launches",
//     icon: launches,
//     numbers: "10+",
//     clickable: true,
//   },
//   {
//     id: 7,
//     text: "Exhibitions",
//     icon: exhibition,
//     numbers: "20+",
//     clickable: true,
//   },
// ];

function AboutFourthSection({
  setconferenceOpenPopup,
  setExhibitionOpenPopup,
  setLauchOpenPopup,
  setSportsOpenPopup,
  setTradeOpenPopup,
}) {
  // const displayCards = () =>
  //   data.map((card) => (
  //     <PortifolioCard setOpenPopup={setOpenPopup} key={card.id} data={card} />
  //   ));

  return (
    <div className="aboutpage__fourth-section">
      <h1 className="custom-heading">Our work</h1>
      <div className="conferencespart">
        <div className="works">
          <p>
            Our relentless strive to satisfy our clientele has paid off. We are
            even more delighted by the continued trust they have in us,
            reflected by the long term agreements we have with notable
            institutions like: the ONE UN, several banks and other private
            organizations.
          </p>
          <div className="cards">
            {/* {displayCards()} */}

            <PortifolioCard
              setOpenPopup={setconferenceOpenPopup}
              data={conferences}
            />
            <PortifolioCard setOpenPopup={() => {}} data={delegates} />
            <PortifolioCard setOpenPopup={() => {}} data={workshops} />
            <PortifolioCard setOpenPopup={() => {}} data={trades} />
            <PortifolioCard
              setOpenPopup={setSportsOpenPopup}
              data={sportsevents}
            />
            <PortifolioCard
              setOpenPopup={setLauchOpenPopup}
              data={launcheslist}
            />
            <PortifolioCard
              setOpenPopup={setExhibitionOpenPopup}
              data={exhibitionscard}
            />
          </div>
        </div>
        {/* <div className="conferencespart__right">
          <img src={conference} alt="conference" className="iconImg" />
          <h1>Conferences:</h1>
          <div className="conferences">
            <ul>{displayConferences()}</ul>
          </div>
        </div> */}
      </div>
      {/* <div className="other-achievements">{displayOtherAchievements()}</div> */}
      <FixedBgImage
        classname="image1"
        text={` 20th Micro, Small, Medium-Sized Enterprises East African Community
            Trade Fair (20th MSMEs EAC Trade Fair 2019) 14th – 23rd December
            2019`}
      />
    </div>
  );
}

export default AboutFourthSection;
