import React from "react";
import Services from "../../components/common/Services";
import pic1 from "../../assets/compressed/aboutP1.jpg";
import pic2 from "../../assets/compressed/aboutP2.jpg";
import pic3 from "../../assets/compressed/aboutP3.jpg";

function AboutSecondSection() {
  return (
    <div className="aboutpage__second-section">
      <div className="upperpart">
        <div className="left">
          <div className="image-container">
            <img src={pic2} alt="youth connect" />
          </div>
          <div className="image-container">
            <img src={pic1} alt="youth connect" />
          </div>
          <div className="image-container">
            <img src={pic3} alt="youth connect" />
          </div>
        </div>
        <div className="right">
          <div className="box">
            <p>
              We are a team of event specialists who have the wealth of
              knowledge and experience in the events industry. It is our
              passion, creativity and first hand knowledge of all our services
              that gives us combined strengths in all aspects of event
              management.
            </p>
            <br />
            <p>
              We take the time necessary to work with and alongside you in order
              to produce your event to the highest possible standard. From
              concept through to completion, our team will work to ensure that
              your objectives are met. Our aim is to get it right the first time
              and know that this will bring clients back to us year after year.
            </p>
          </div>
          <div className="box">
            <h1>Our services</h1>
            <p>
              We are a full service event management company providing an array
              of services like event logistics and transport, online
              registration and payment, delegate accommodation and digital
              delegate management, audio visual production, simultaneous
              interpretation services, branding, media management, exhibition
              management among others.
            </p>
            <br />
            <p>
              We would be delighted if invited for a meeting before taking any
              decisions as this would allow us share our experience and thus
              save you money and time or build your event to an even higher
              profile.
            </p>
            <Services />
          </div>
        </div>
      </div>
      <div className="lowerpart"></div>
    </div>
  );
}

export default AboutSecondSection;
