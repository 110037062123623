import React from "react";

function TestimonyCard({ data }) {


  if (data.last) {
    return (
      <div
        className="testimony-card"
        id="last-testimony"
       
      >
        <img src={data.ifoto} alt="Events factory" />
        <h1>{data.name}</h1>
        <h3>{data.position}</h3>
        <p>{data.text}</p>
      </div>
    );
  }
  return (
    <div className="testimony-card">
      <img src={data.ifoto} alt="Events factory" />
      <h1>{data.name}</h1>
      <h3>{data.position}</h3>
      <p>{data.text}</p>
    </div>
  );
}

export default TestimonyCard;
