import React from "react";

function MemberTitleContainer({name, title}) {
  return (
    <div className="titlecontainer">
      <p className="p1">{name}</p>
      <p className="p2">{title}</p>
    </div>
  );
}

export default MemberTitleContainer;
