import React from "react";

function IconContainer({icon}) {
  return (
    <div className="icon-container">
      <img src={icon} alt="company" />
    </div>
  );
}

export default IconContainer;
